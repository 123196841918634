import kldbValues from "../../../candidate-sourcing/tenantsData/kldbValues/kldbSearchValues.json";

import moment from "moment";
import { regExpHelper } from "../../../../helper/regexp-helper";
import { toLiteral } from "../../../../helper/locale-utils";
import { arrayDateNameToObject, generateRangesDates, getValueDate } from "../../../../helper/form-ui";
import { INPUT_ATTACHMENT_MAX_SIZE } from "../../../../constants";
import { getScoreToPassValue } from "../../../recruitment/processes/edit/process-creation-helpers/process-creation-helper";
import { getDateFormatFromTenantOrBrowser } from "../../../../helper/date-format-helper";

const invalidNameWithHyphenRegExp = /[\x21-\x26\x28-\x2C\x2E-\x2F\x3A-\x40\x5B-\x5D\x7B-\x7D]/;

const validationIsGood = undefined;

export const required = (value: string | string[] | undefined) => {
  if (typeof value === "number") {
    return validationIsGood;
  }
  if (typeof value === "object") {
    const value2: any = value;
    if (value2?.type === "FileSelector") {
      if (!value2?.state?.files?.length) {
        return toLiteral({ id: "validations.required" });
      }
    } else if (!value?.length) {
      return toLiteral({ id: "validations.required" });
    }
  } else if (typeof value === "string") {
    if (value.replace(/\s/g, "").length > 0) {
      return validationIsGood;
    } else {
      return toLiteral({ id: "validations.required" });
    }
  } else if (value !== null && typeof value !== "undefined") {
    return validationIsGood;
  } else {
    return toLiteral({ id: "validations.required" });
  }
};
export const isSupportedMimeType = (input: any) => {
  const _accept = input?.props?.accept as string;
  const _current = (input?.props?.fileType || input?.state?.fileType) as string;
  return !_current || _accept?.includes(_current) ? validationIsGood : toLiteral({ id: "validations.file.unsupported" });
};

export const maximumFileSize = (input: any, options?: any) => {
  const maxSize = options?.maxSize || input?.props?.maximumSize || INPUT_ATTACHMENT_MAX_SIZE;
  const fileSize = options?.fileSize || (input?.state?.fileSize as number);
  return !fileSize || fileSize < maxSize ? validationIsGood : toLiteral({ id: "validations.file.tooLarge" });
};
export const checkIfVariablesAreDestroyed = (variables: any) => (text: string, inputComponent: any) => {
  if (text === undefined) return validationIsGood;
  const customVariables = variables;
  let incorrectVariable = false;
  const variablesText = text.match(/[{]{1}\w*[}]{1}/gm);
  const variablesInsideVar = text.match(/[{]{1}\w+[{]{1}\w+[}]{1}\w+[}]{1}/gm);
  const variablesWithEnter = text.match(/[{]{1}\w+[\n]/gm);
  if ((variablesInsideVar && variablesInsideVar?.length) || (variablesWithEnter && variablesWithEnter?.length)) {
    incorrectVariable = true;
  }
  const distincVariables = variablesText?.filter((variable: string, index: number) => variablesText?.indexOf(variable) === index);
  distincVariables?.forEach((variable: string) => {
    const variableKey = variable.replace("{", "").replace("}", "").trim();
    const variableFound = customVariables?.find((vari: any) => vari.name === variableKey);
    if (!variableFound) {
      incorrectVariable = true;
    }
  });
  return incorrectVariable ? toLiteral({ id: "validations.incorrect.variable" }) : validationIsGood;
};

export const isValidName = (value: string) => (regExpHelper.isInvalidProcessNameAlphabet(value ?? "") && value?.length !== 0 ? toLiteral({ id: "field-invalid-char" }) : validationIsGood);

export const name = (value: string) => {
  return value && !value.match(regExpHelper.nameRegExp) ? toLiteral({ id: "validations.must.be.text" }) : validationIsGood;
};
export const alphabet = (value: any) => {
  return value && /[\x21-\x26\x28-\x2F\x3A-\x40\x5B-\x5D\x7B-\x7D]/i.test(value) ? toLiteral({ id: "validations.must.be.alphabet" }) : validationIsGood;
};
export const alphanumeric = (value: any) => {
  return value && !/^[A-Z0-9]+$/i.test(value) ? toLiteral({ id: "validations.must.be.alphanumeric" }) : validationIsGood;
};

export const numeric = (value: any) => {
  return value && !/^[0-9]+$/i.test(value) ? toLiteral({ id: "validations.must.be.numeric" }) : validationIsGood;
};

export const numericWithDecimal = (precision: number) => (value: any) => {
  const regexString = `^\\d+\\.?\\d{0,${precision}}$`;
  const isValid = new RegExp(regexString).test(value);
  return value && !isValid ? `${toLiteral({ id: "validations.must.be" })} ${precision} ${toLiteral({ id: "validations.less.decimals" })}` : validationIsGood;
};

export const uuid = (value: any) => {
  return value && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(value) ? toLiteral({ id: "validations.must.be.UUID" }) : validationIsGood;
};

export const email = (value: any) => {
  return value && !/^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? toLiteral({ id: "public.descriptionEmail" }) : validationIsGood;
};
export const phone = (value: any) => {
  return value && !regExpHelper.isPhone(value) ? toLiteral({ id: "validations.phone.invalid" }) : validationIsGood;
};
export const _void = (_value: any) => {
  return validationIsGood;
};
export const url = (value: any) => {
  return value && !/^((https|http):\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[-a-zA-Z0-9()@:%_+.~#?&/=]*|\{[-a-zA-Z0-9_]+\})*$/i.test(value)
    ? toLiteral({ id: "validations.invalid.url" })
    : validationIsGood;
};
export const urlInternalOrExternal = (value: any) => {
  if (value && value.indexOf("/") === 0) {
    return validationIsGood;
  } else {
    return url(value);
  }
};
export const hexacode = (value: any) => {
  return value && !/^#([A-F0-9]{3}|[A-F0-9]{6})$/i.test(value) ? toLiteral({ id: "validations.invalid.hexacode" }) : validationIsGood;
};

export const validateAlphabetWithHyphen = (value: string) => {
  return invalidNameWithHyphenRegExp.test(value) ? toLiteral({ id: "validations.invalid.character" }) : validationIsGood;
};
export const fiscalCode = (value: string) => {
  return value && !/^([A-Za-z]){6,6}([A-Za-z0-9]){2,2}([A-Za-z]){1,1}([A-Za-z0-9]){7,7}$/i.test(value) ? toLiteral({ id: "validations.invalid.fiscal.code" }) : validationIsGood;
};

export const notAllowZeroAtTheBeggining = (valueSelected: string) => (value: any) => {
  return value && !/^((?!(0))[0-9]{0,})$/g.test(valueSelected) ? toLiteral({ id: "validations.must.be.start.without.zero" }) : validationIsGood;
};

export const onlyDigits = (value: any) => {
  return value && !/^[0-9]+$/.test(value) ? toLiteral({ id: "validations.must.contain.only.digits" }) : validationIsGood;
};

export const date = (value: any, _context: any, inputComponent: any) => {
  if (!value || Object.prototype.toString.call(value) === "[object Date]") {
    return validationIsGood;
  } else {
    let isDate = null;
    let format = inputComponent && inputComponent.props.dateFormat ? inputComponent.props.dateFormat : null;
    if (value) {
      if (format) {
        isDate = moment(value, format, true).isValid();
      } else {
        isDate = moment(value).isValid();
      }
    }
    if (isDate) {
      return validationIsGood;
    }
    const formatMessage = " (" + format + ")";
    return toLiteral({ id: "validations.incorrect.date" }) + formatMessage;
  }
};

export const dateAndHour = (value: any, _context: any, inputComponent: any) => {
  if (!value || Object.prototype.toString.call(value) === "[object Date]") {
    return validationIsGood;
  } else {
    let isDate = null;
    let format = inputComponent && inputComponent.props.dateFormat ? inputComponent.props.dateFormat : null;
    if (value) {
      if (format) {
        isDate = moment(value, format, true).isValid() || moment(value, `${format} HH:mm`, true).isValid();
      } else {
        isDate = moment(value).isValid();
      }
    }
    if (isDate) {
      return validationIsGood;
    }
    const formatMessage = " (" + `${format} HH:mm` + ")";
    return toLiteral({ id: "validations.incorrect.date" }) + formatMessage;
  }
};

export const validateCustomPhone = (specificPhone: any) => (value: string) => {
  if (specificPhone?.regex !== undefined) {
    if (value?.match(specificPhone?.regex)) {
      return validationIsGood;
    }
    return toLiteral({ id: specificPhone?.description });
  }
  if (regExpHelper.isPhone(value)) {
    return validationIsGood;
  }
  return toLiteral({ id: "validations.phone.invalid" });
};

export const minLength = (minLengthValue: any, label?: string, noZeros?: boolean) => (value: any) => {
  let condition = noZeros ? parseInt(value)?.toString()?.length < minLengthValue : value?.length < minLengthValue;
  return condition ? `${toLiteral({ id: "validations.must.be" })} ${minLengthValue} ${toLiteral({ id: label ?? "validations.characters.more" })}` : validationIsGood;
};

export const maxLength = (maxLengthValue: any, customText?: string, noZeros?: boolean) => (value: any) => {
  let condition = noZeros ? parseInt(value)?.toString()?.length > maxLengthValue : value?.length > maxLengthValue;
  if (condition) {
    if (customText) return toLiteral({ id: customText });
    return `${toLiteral({ id: "validations.must.be" })} ${maxLengthValue} ${toLiteral({ id: "validations.characters.less" })}`;
  }
  return validationIsGood;
};

export const maxLengthByComma = (maxLengthValue: any) => (value: any) => {
  const uuidPatron = "[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}";
  const regexString = `^(${uuidPatron})(,\\s*(${uuidPatron})){0,${maxLengthValue - 1}}$`;
  //Delete the \n
  const currentValue = value.replace(/\n/g, "").trim();
  const isValid = new RegExp(regexString).test(currentValue);
  return !isValid ? `${toLiteral({ id: "validation.uuid.jds" })} ${maxLengthValue}` : validationIsGood;
};

export const totalLength = (totalLengthValue: any) => (value: any) =>
  value && (value.length > totalLengthValue || value.length < totalLengthValue)
    ? `${toLiteral({ id: "validations.must.be" })} ${totalLengthValue} ${toLiteral({ id: "validations.characters" })}`
    : validationIsGood;

export const min = (minValue: any) => (value: any) => value < minValue ? `${toLiteral({ id: "validations.value.higher" })} ${minValue}` : validationIsGood;

export const max = (maxValue: any) => (value: any) => value > maxValue ? `${toLiteral({ id: "validations.value.lower" })} ${maxValue}` : validationIsGood;

export const min1 = (value: any) => (value < 1 ? `${toLiteral({ id: "validations.value.higher" })} 1` : validationIsGood);

export const passwordsMatch = (value: any, context: any): any => {
  const otherPwdField = context.inputs.find((a: any) => a.props.name === "password");

  return value !== otherPwdField.state.value ? toLiteral({ id: "validations.password.noMatch" }) : validationIsGood;
};
export const minValidator = (value: any, allValues: any) => (value > allValues.max ? toLiteral({ id: "validations.min.higher.max" }) : validationIsGood);

export const maxValidator = (value: any, allValues: any) => (value < allValues.min ? toLiteral({ id: "validations.max.lower.min" }) : validationIsGood);

export const minYears = (minYearsValue: any, customMessage?: string) => (value: any, _context: any, inputComponent: any) => {
  if (value) {
    let _date = null;
    let isValidAge = false;
    let format = inputComponent && inputComponent.props.dateFormat ? inputComponent.props.dateFormat : null;
    // Get date in format
    if (format) {
      _date = moment(value, format, true);
    } else {
      _date = moment(value);
    }
    // Check if is valid
    if (_date.isValid()) {
      const years = moment().diff(_date, "years");
      isValidAge = years >= minYearsValue;
    }
    return !isValidAge ? (customMessage ? customMessage : `${toLiteral({ id: "validations.must.be" })} ${minYearsValue} ${toLiteral({ id: "validations.age.years.more" })}`) : validationIsGood;
  }
};

export const minYears16 = minYears(16);
export const minYears18 = minYears(18);
export const minYears21 = minYears(21);
export const minCustomYears16 = minYears(16, toLiteral({ id: "custom.it.message.birthday" }));

export const mustContainLowerCase = (value: any) => {
  const passwordRegex = new RegExp("^((?=.*[a-z]))");
  return value && !passwordRegex.test(value) ? toLiteral({ id: "validations.password.contain.lowercase" }) : validationIsGood;
};

export const mustContainUpperCase = (value: any) => {
  const passwordRegex = new RegExp("^((?=.*[A-Z]))");
  return value && !passwordRegex.test(value) ? toLiteral({ id: "validations.password.contain.uppercase" }) : validationIsGood;
};

export const mustContainSpecialCharacter = (value: any) => {
  // prettier-ignore
  const passwordRegex = new RegExp("^((?=.*[!@#$%^&*]))");
  return value && !passwordRegex.test(value) ? toLiteral({ id: "validations.password.contain.special" }) : validationIsGood;
};

export const isChecked = (input: any, context: any) => {
  if (context && context.inputs && context.inputs.length > 0) {
    return checkIfOneChecked(input, context);
  } else {
    return input && input.state && input.state.checked ? validationIsGood : toLiteral({ id: "validations.required" });
  }
};

export const isRadioChecked = (input: any, context: any) => {
  // Maintain this function in order to have separte logic
  // Avoid codesmell
  return isChecked(input, context);
};

function checkIfOneChecked(input: any, context: any) {
  let thereIsOneChecked = false;
  context.inputs.forEach((element: any) => {
    if (!thereIsOneChecked && element.props.name === input.props.name && element.state.checked) {
      thereIsOneChecked = true;
    }
  });
  return thereIsOneChecked ? validationIsGood : toLiteral({ id: "validations.required" });
}

export const hasFileDropFiles = (input: any, _context: any) => {
  return input && input.state && input.state.files && input.state.files.length ? validationIsGood : toLiteral({ id: "validations.required" });
};
export const hasFileUploaderFiles = (input: any, _context: any) => {
  return input && input.state && input.state.filesValue?.length > 0 ? validationIsGood : toLiteral({ id: "validations.required" });
};

export const hasFileSelectorFiles = (input: any) => {
  return input && input.state && input.state.value && input.state.value.length ? validationIsGood : toLiteral({ id: "validations.required" });
};

export const kldbValid = (value: number) => {
  const foundKldbValue = kldbValues.find((kldb: any) => kldb.value === value);
  return foundKldbValue ? validationIsGood : toLiteral({ id: "validations.notfound.kldb" });
};

// fromFake -> Avoid cycles in related

export interface IValidationSummaryItem {
  valid: boolean;
  inputComponent: any;
  name: string;
  value: any;
  errors: string[];
}
export interface IValidationSummary {
  valid: boolean;
  items: IValidationSummaryItem[];
}
/**
 * This function calls behind the sceenes validateInputVerbose with the difference that only returns true or false rather than an object sumarizing the errors.
 * This function goes through all validation functions passed as properties in an input, and uses them to verify the value matches all of them, if the value fails in any of those, the input would be marked as Invalid setting it's stat
 * @returns true or false
 * @param inputComponent - Is the actual input component
 * @param context - Is the form context, where the input is loaded
 */
export async function validateInput(inputComponent: any, context: any, fromFake?: boolean) {
  const validationSumaryItem: IValidationSummaryItem = await validateInputVerbose(inputComponent, context, fromFake);
  return validationSumaryItem.valid;
}

/**
 * This function goes through all validation functions passed as properties in an input, and uses them to verify the value matches all of them, if the value fails in any of those, the input would be marked as Invalid setting it's state
 * @returns an object that will contain the following
 *  IValidationSummaryItem { valid: boolean; inputComponent: any; name: string; value: any; errors: string[]; }
 * @param inputComponent - Is the actual input component
 * @param context - Is the form component, where the input is loaded
 */
export async function validateInputVerbose(inputComponent: any, context: any, fromFake?: boolean) {
  const inputValidations = inputComponent.props.validations || [];
  let validationSumaryItem: IValidationSummaryItem = {
    valid: true,
    inputComponent: inputComponent,
    name: inputComponent?.props?.name,
    value: inputComponent?.state?.value,
    errors: [],
  };
  if (inputComponent.props.visible !== false && (inputValidations?.length || inputComponent.state.requireByRelated) && !inputComponent.state.disabled) {
    let arrayOfErrors: string[] = [];
    if (inputComponent._isMounted) {
      inputComponent.setState({ loading: true });
    }
    // Required related
    if (inputComponent.state.requireByRelated) {
      const errorRelatedRequired = required(inputComponent.state.value);
      if (typeof errorRelatedRequired !== "undefined") arrayOfErrors.push(toLiteral({ id: "validations.required.related" }));
    }
    for await (const validationFunction of inputValidations) {
      if (typeof validationFunction === "function") {
        const type = inputComponent.state.type;
        let errorMessage: string = "";
        switch (type) {
          case "checkbox":
            if (validationFunction === required) errorMessage = isChecked(inputComponent, context);
            else errorMessage = await validationFunction(inputComponent.state.value, context, inputComponent, fromFake);
            break;
          case "radio":
            if (validationFunction === required) errorMessage = isRadioChecked(inputComponent, context);
            else errorMessage = await validationFunction(inputComponent.state.value, context, inputComponent, fromFake);
            break;
          case "FileDrop":
            errorMessage = hasFileDropFiles(inputComponent, context);
            break;
          case "FileUploader":
            errorMessage = hasFileUploaderFiles(inputComponent, context);
            break;
          case "FileSelector": //add required validator if content is mandatory
            errorMessage = validationFunction(inputComponent);
            break;
          default:
            errorMessage = await validationFunction(inputComponent.state.value, context, inputComponent, fromFake);
        }

        if (typeof errorMessage !== "undefined") {
          arrayOfErrors.push(errorMessage);
        }
        validationSumaryItem.errors = arrayOfErrors;
      }
    }
    if (arrayOfErrors && arrayOfErrors.length) {
      if (inputComponent._isMounted) {
        inputComponent.setState({
          errors: arrayOfErrors,
          isValid: false,
          loading: false,
        });
      }
      validationSumaryItem.valid = false;
      return validationSumaryItem;
    } else {
      if (inputComponent._isMounted) {
        inputComponent.setState({ errors: null, isValid: true, loading: false });
      }
      return validationSumaryItem;
    }
    // If not validation is valid
  } else {
    if (inputComponent._isMounted) {
      inputComponent.setState({ isValid: true });
    }
    return validationSumaryItem;
  }
}

export const dateTo = (value: any, context: any, inputComponent: any) => {
  try {
    let fromDate = null;
    let nameToSearch = "";
    const nameField = inputComponent.props.name;
    if (inputComponent.props.name === "postEndDate") {
      nameToSearch = "postStartDate";
      fromDate = context?.inputs?.find((a: any) => a.props.name === nameToSearch && a._isMounted);
    } else {
      nameToSearch = nameField.replace("end", "start");
      fromDate = context?.inputs?.find((a: any) => a.props.name === nameToSearch && a._isMounted);
    }
    if (fromDate) validateInput(fromDate, context); //validate the sibling input
    return moment(value, getDateFormatFromTenantOrBrowser(), true) < moment(fromDate.state.value, getDateFormatFromTenantOrBrowser(), true)
      ? toLiteral({ id: "validations.start.date.later" })
      : validationIsGood;
  } catch (error) {}
};

export const dateFrom = (value: any, context: any, inputComponent: any) => {
  try {
    let toDate = null;
    let nameToSearch = "";
    const nameField = inputComponent.props.name;
    if (inputComponent.props.name === "postStartDate") {
      nameToSearch = "postEndDate";
      toDate = context?.inputs?.find((a: any) => a.props.name === nameToSearch && a._isMounted);
    } else {
      nameToSearch = nameField.replace("start", "end");
      toDate = context?.inputs?.find((a: any) => a.props.name === nameToSearch && a._isMounted);
    }
    return moment(value, getDateFormatFromTenantOrBrowser(), true) > moment(toDate.state.value, getDateFormatFromTenantOrBrowser(), true)
      ? toLiteral({ id: "validations.end.date.earlier" })
      : validationIsGood;
  } catch (error) {}
};

export const dateRangeStart = (value: any, context: any, inputComponent: any) => {
  return checkRanges(true, value, context, inputComponent);
};
export const dateRangeEnd = (value: any, context: any, inputComponent: any) => {
  return checkRanges(false, value, context, inputComponent);
};

const checkRanges = (isStart: boolean, value: any, context: any, inputComponent: any) => {
  let error = false;
  // GET CURRENT RANGE AND OTHERS
  const current = {
    start: isStart && value ? getValueDate(value) : null,
    end: !isStart && value ? getValueDate(value) : null,
  };
  const nameField = inputComponent.props.name || "";
  const nameParts = arrayDateNameToObject(nameField);
  if (value && nameParts) {
    const { rangesDates, missingCurrentPart } = generateRangesDates(isStart, nameParts.group, nameParts.index, context?.inputs);
    // Set other part of range
    if (!isStart) current.start = missingCurrentPart;
    else current.end = missingCurrentPart;

    // Filter out ranges with Ended status (#77102)
    const filteredRangesDates = Object.fromEntries(Object.entries(rangesDates).filter(([key, post]) => post.statusId !== "11"));

    // Go thought all ranges
    Object.keys(filteredRangesDates).forEach((rangeDates) => {
      const startCurrent = current.start;
      const endCurrent = current.end;
      const end = rangesDates[rangeDates].end;
      const start = rangesDates[rangeDates].start;
      // THIS IS THE LOGIC TO VALIDATE
      const currentRangeComplete = startCurrent && endCurrent ? true : false;
      // Is valid range to compare
      if (start && end) {
        if ((isStart || currentRangeComplete) && startCurrent >= start && startCurrent < end) error = true;
        if ((!isStart || currentRangeComplete) && endCurrent > start && endCurrent <= end) error = true;
        if (currentRangeComplete && startCurrent < start && endCurrent > end) error = true;
      }
    });
  }
  return error ? toLiteral({ id: "validations.range.date.overlaping" }) : validationIsGood;
};

export const dateBefore = (value: any, context: any) => {
  try {
    const nameToSearch = "expiryDate";
    const toDate = context?.props?.additionalData?.[nameToSearch];
    if (!toDate) return validationIsGood;
    return moment(value)?.valueOf() > moment(toDate).valueOf() ? toLiteral({ id: "validations.date.must.be.earlier" }) : validationIsGood;
  } catch (error) {}
};

export const dateAfter = (value: any, context: any) => {
  try {
    const nameToSearch = "expirationDate";
    let dates: any[] = [];
    if (context?.props?.additionalData?.[nameToSearch] && context?.props?.additionalData?.pcbExpirationOption && context?.props?.additionalData?.pcbExpirationOption !== "syncExpirationDate") {
      dates.push(context?.props?.additionalData?.[nameToSearch]);
    }
    if (context?.props?.additionalData?.[nameToSearch] && context?.props?.additionalData?.liExpirationOption && context?.props?.additionalData?.liExpirationOption !== "syncExpirationDate") {
      dates.push(context?.props?.additionalData?.[nameToSearch]);
    }
    if (context?.props?.additionalData?.phoneCall?.[nameToSearch] && context?.props?.additionalData?.phoneCall?.pcbExpirationOption !== "syncExpirationDate") {
      dates.push(context?.props?.additionalData?.phoneCall?.[nameToSearch]);
    }
    if (context?.props?.additionalData?.branchInterview?.[nameToSearch] && context?.props?.additionalData?.branchInterview?.pcbExpirationOption !== "syncExpirationDate") {
      dates.push(context?.props?.additionalData?.branchInterview?.[nameToSearch]);
    }
    if (context?.props?.additionalData?.liveInterview?.[nameToSearch] && context?.props?.additionalData?.liveInterview?.liExpirationOption !== "syncExpirationDate") {
      dates.push(context?.props?.additionalData?.liveInterview?.[nameToSearch]);
    }
    if (dates.length === 0) return validationIsGood;
    const valueDate = moment(value).valueOf();
    const isBeforeAnyDate = dates.some((dateItem) => valueDate < moment(dateItem).valueOf());
    if (isBeforeAnyDate) return toLiteral({ id: "validations.expiration.date.higher" });
    else return validationIsGood;
  } catch (error) {}
};

export const dateFromNow = (value: any, _context: any, inputComponent: any) => {
  try {
    let format = inputComponent && inputComponent.props.dateFormat ? inputComponent.props.dateFormat : null;
    const dateTimeFormat = `${format} HH:mm`;
    const dateTime = moment(value, dateTimeFormat, true);
    if (dateTime.isValid()) {
      return dateTime.valueOf() < moment().valueOf() ? toLiteral({ id: "creator.error.endDate" }) : validationIsGood;
    } else return moment(value)?.valueOf() < moment().valueOf() ? toLiteral({ id: "validations.date.must.be.after" }) : validationIsGood;
  } catch (error) {}
};

export const validateStars = (_value: any, context: any, inputComponent: any) => {
  try {
    const starsHiddenInput = context?.inputs?.find((a: any) => a?.props?.name === inputComponent?.props?.dataStarsName);
    if (starsHiddenInput?.state?.value && starsHiddenInput?.state?.value !== "null" && starsHiddenInput?.state?.value !== "undefined") {
      return validationIsGood;
    } else {
      return toLiteral({ id: "validations.score.must.be.selected" });
    }
  } catch (error) {}
};

export const ageRestriction = (minimalAge: any, dateFormat: string) => (value: any) => {
  try {
    const years = moment(new Date()).diff(moment(value, dateFormat), "years");
    const errorMessage = `${toLiteral({ id: "administrator.services.candidate.minimalCandidateAllowedAge" })} ${minimalAge}`;
    return years < minimalAge ? errorMessage : validationIsGood;
  } catch (error) {}
};

export const onePostingSelected = (value: any, context: any, inputComponent: any, fromFake?: boolean) => {
  let oneChecked = false;
  const postType = context?.inputs?.filter((a: any) => a.props.name === "postType" && a._isMounted);
  const postMultiposting = context?.inputs?.find((a: any) => a.props.name === "postMultiposting" && a._isMounted);
  postType.forEach((_postType: any) => {
    if (_postType.state?.checked) oneChecked = true;
  });
  if (postMultiposting?.state?.checked) oneChecked = true;
  // Validate the sibling input
  if (!fromFake) {
    const inputName = inputComponent.props.name;
    if (inputName === "postType") {
      if (postMultiposting) validateInput(postMultiposting, context, true);
    } else {
      if (postType?.length && postType.length > 0) {
        postType.forEach((_postType: any) => {
          validateInput(_postType, context, true);
        });
      }
    }
  }
  // Return and sibling
  return !oneChecked ? toLiteral({ id: `one.publisher.should.selected.to.submit` }) : validationIsGood;
};

export const minSalaryValidation = (value: any, context: any, inputComponent: any, fromFake?: boolean) => {
  const allMaxValueInputs = context?.inputs?.filter((input: any) => input.props.name === "dataSalaryMax"); //there are more than one field with this name
  const maxValueInput = allMaxValueInputs[allMaxValueInputs.length - 1]; //always compare with the last one being used
  const maxValue = maxValueInput?.value;
  const minValue = parseFloat(value);
  if (minValue > maxValue && maxValue !== "") {
    return toLiteral({ id: "validations.min.higher.max" });
  }
  if (!maxValueInput?.state?.isValid && !fromFake) validateInput(maxValueInput, context, true); //validate salary max
};

export const maxSalaryValidation = (value: any, context: any, inputComponent: any, fromFake?: boolean) => {
  const allMinValueInputs = context?.inputs?.filter((input: any) => input.props.name === "dataSalaryMin"); //there are more than one field with this name
  const minValueInput = allMinValueInputs[allMinValueInputs.length - 1]; //always compare with the last one being used
  const minValue = minValueInput?.value;
  const maxValue = parseFloat(value);
  if (minValue > maxValue) {
    return toLiteral({ id: "validations.max.lower.min" });
  }
  if (!minValueInput?.state?.isValid && !fromFake) validateInput(minValueInput, context, true); //validate salary min
};

export const minScheduleWindow = (value: any, context: any) => {
  const allMaxValueInputs = context?.inputs?.filter((input: any) => input.props.name === "endSchedulingPeriod");
  const maxValueInput = allMaxValueInputs[allMaxValueInputs.length - 1];
  const maxValue = maxValueInput?.value;
  const minValue = parseFloat(value);
  if (minValue > maxValue && maxValue !== "") {
    return toLiteral({ id: "validations.min.higher.max" });
  } else {
    return validationIsGood;
  }
};

export const maxScheduleWindow = (value: any, context: any) => {
  const allMinValueInputs = context?.inputs?.filter((input: any) => input.props.name === "startSchedulingPeriod");
  const minValueInput = allMinValueInputs[allMinValueInputs.length - 1];
  const minValue = minValueInput?.value;
  const maxValue = parseFloat(value);
  if (minValue > maxValue) {
    return toLiteral({ id: "validations.max.lower.min" });
  } else {
    return validationIsGood;
  }
};

export const isNumber = (value: any) => {
  return isNaN(value) ? toLiteral({ id: "validations.must.be.number" }) : validationIsGood;
};

export const scoreToPassMinValue = (value: any, context: any) => {
  try {
    const phase = context?.props?.phaseData;
    const scoreToPass = getScoreToPassValue(phase);
    return parseInt(value) < scoreToPass ? toLiteral({ id: "Score to pass should be at least grater than total required score" }) + ` (${scoreToPass})` : validationIsGood;
  } catch (error) {}
};

export const validGSM338 = (value: any) => {
  const GSM_3_38_CHARS = new Set([
    "@",
    "£",
    "$",
    "¥",
    "è",
    "é",
    "ù",
    "ì",
    "ò",
    "Ç",
    "Ø",
    "ø",
    "Å",
    "å",
    "Δ",
    "_",
    "Φ",
    "Γ",
    "Λ",
    "Ω",
    "Π",
    "Ψ",
    "Σ",
    "Θ",
    "Ξ",
    "Æ",
    "æ",
    "ß",
    "É",
    " ",
    "!",
    '"',
    "#",
    "¤",
    "%",
    "&",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    "-",
    ".",
    "/",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "¡",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "Ä",
    "Ö",
    "Ñ",
    "Ü",
    "§",
    "¿",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "ä",
    "ö",
    "ñ",
    "ü",
    "à",
    // Escape characters
    "\f",
    "^",
    "{",
    "}",
    "\\",
    "[",
    "~",
    "]",
    "|",
    "€",
  ]);

  let valid = true;
  for (let char of value) {
    if (!GSM_3_38_CHARS.has(char)) {
      valid = false;
    }
  }

  return valid ? validationIsGood : toLiteral({ id: 'All charcters must be part of "GSM 3.38 Character Set" standard' });
};
