import { getPhaseNameByType, toLiteral, CURRENT_LOCALE } from "../../../../../helper/locale-utils";
import { PhaseType, SelectedTenantObject, Sources, ProcessPhase, ProcessStatus, Process } from "../../../../../types";
import * as Api from "../../../../../api";
import { getFeatureConfigurationIsEnabled, getFeatureConfiguration, getFeatureConfigurationValues } from "../../../../../helper/feature-configuration-helper";
import moment from "moment";
import { STORAGE_BASE_PATH, ROLE_ADMIN, ROLE_SUPER_ADMIN, DEFAULT_LANGUAGE, PHASE_PROFILES, REMINDER_MAILS_FRECUENCY_FILTER } from "../../../../../constants";
import store from "../../../../../redux/store";
import { getTenantRole } from "../../../../../helper/role-helper";
import { addNotification } from "../../../../shared/notifications/notifications-service";

let delayTimer: NodeJS.Timeout;

export interface InfoParamsValue {
  externalId?: string;
  expirationDate?: string;
  source?: string;
}

// Check Source
export function checkIfIsCASource(phasesToSearch: ProcessPhase[] = []): boolean {
  let isCASource: boolean = false;
  phasesToSearch.forEach((phase) => {
    if (phase.type === PhaseType.screeningQuestions && phase.isRunByCAChatbot) isCASource = true;
  });
  return isCASource;
}
export function getInfoSource(infoParams?: InfoParamsValue, itemPhases?: ProcessPhase[]): string {
  if (infoParams?.source) return infoParams?.source;
  else {
    if (checkIfIsCASource(itemPhases)) return Sources.careerAssistant;
    if (infoParams?.externalId) return Sources.infocore;
    else return Sources.recruitmentConnect;
  }
}

export async function generateNewProcess(data: any, tenant: SelectedTenantObject, categoryId?: any, infoParamsValue?: InfoParamsValue, publicName?: string): Promise<any> {
  let itemPhases: any = [];

  if (categoryId && data?.template) {
    // Load template info and phases
    try {
      const template = await Api.fetchTemplate(categoryId, data?.template);
      itemPhases = template.phases;
    } catch (errTemplate) {
      const e: any = errTemplate;
      console.error("Template not found", e.message);
      return;
    }
  } else {
    // CREATE BLANK TEMPLATE
    itemPhases = [
      { name: toLiteral({ id: "applicationStageName" }), title: "", type: -1, message: "", mediaType: "" },
      { name: toLiteral({ id: "creator.end.stepper" }), title: "", type: -2, message: "", mediaType: "" },
    ];
  }

  let processObject = {
    name: data?.name,
    professionId: data?.category,
    phases: itemPhases,
    templateId: data?.template,
    regionId: tenant?.regionId || undefined,
    branchId: tenant?.branchId || undefined,
    zoneId: tenant?.zoneId || undefined,
    externalId: infoParamsValue?.externalId ? infoParamsValue?.externalId : undefined,
    expiryDate: infoParamsValue?.expirationDate ? moment(infoParamsValue?.expirationDate).valueOf() : undefined,
    source: getInfoSource(infoParamsValue, itemPhases),
    publicName: publicName || undefined,
  };

  return processObject;
}

export function processPhasesToSingleForm(process: any) {
  let processPhasesForm = {};
  process?.phases?.forEach((phase: any) => {
    processPhasesForm = { ...processPhasesForm, ...phase };
  });
  return processPhasesForm;
}

export async function getProcessPhases(isAdmin: boolean, process?: any, filterByPhaseAuth?: boolean) {
  // Initialize basic phases
  let examplePhases = [
    { name: getPhaseNameByType(PhaseType.video), type: PhaseType.video },
    { name: getPhaseNameByType(PhaseType.screeningQuestions), type: PhaseType.screeningQuestions },
  ];

  // Check if phases are enabled for the tenant
  if (getFeatureConfigurationIsEnabled("CAMMIO", "video-interview-phase-step")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.videoInterview), type: PhaseType.videoInterview });
  }

  if (getFeatureConfigurationIsEnabled("CAMMIO", "live-interview-phase-step")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.liveInterview), type: PhaseType.liveInterview });
  }

  if (getFeatureConfigurationIsEnabled("General Settings", "skill-assessment") && getFeatureConfigurationIsEnabled("General Settings", "hard-skill-assessment")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.hardSkill), type: PhaseType.hardSkill });
  }

  if (getFeatureConfigurationIsEnabled("General Settings", "skill-assessment") && getFeatureConfigurationIsEnabled("General Settings", "soft-skill-assessment")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.softSkill), type: PhaseType.softSkill });
  }

  if (getFeatureConfigurationIsEnabled("Process Settings", "phone-call-phase")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.phoneCall), type: PhaseType.phoneCall });
  }
  if (getFeatureConfigurationIsEnabled("Candidate Settings", "candidate-presentation")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.candidatePresentation), type: PhaseType.candidatePresentation });
  }
  if (getFeatureConfigurationIsEnabled("Process Phases", "shortlist")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.shortlist), type: PhaseType.shortlist });
  }

  if (getFeatureConfigurationIsEnabled("Process Phases", "reference-check")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.reference), type: PhaseType.reference });
  }
  if (getFeatureConfigurationIsEnabled("Process Phases", "offer-management")) {
    examplePhases.push({ name: getPhaseNameByType(PhaseType.offerManagement), type: PhaseType.offerManagement });
  }

  const checkAuth = (type: number) => {
    const _phase = process?.phases?.find((_item: any) => _item.type === type);
    const selectedUserProfile = store?.getState()?.tenant?.selectedUserProfile;
    const nonFilteredProfiles = ["admin", "superAdmin", "user", "user-view-only"];
    if (_phase && selectedUserProfile !== undefined && _phase?.authorizedProfiles?.length && !nonFilteredProfiles.includes(selectedUserProfile ?? "")) {
      if (!_phase?.authorizedProfiles?.includes(selectedUserProfile)) return false;
    }
    return true;
  };

  if (filterByPhaseAuth && process?.phases) {
    // Checks if there is authorizedProfiles parameter in phase object and check user profile if so
    let allowedPhases: any = [];
    examplePhases?.forEach((phase: any) => {
      if (checkAuth(phase.type)) {
        allowedPhases.push(phase);
      }
    });
    examplePhases = allowedPhases;
  } else {
    //Check the recruiter modules
    if (!!isAdmin && getFeatureConfigurationIsEnabled("General Settings", "user-profile-modules")) {
      const allowedModules = await Api.fetchRecruitersModulesAllowed();
      let allowedPhases: any = [];
      allowedModules.forEach((phase: number) => {
        const module = examplePhases.find((example: any) => example.type === phase);
        if (module) allowedPhases.push(module);
      });
      examplePhases = allowedPhases;
    }
  }

  return examplePhases;
}

export function filterByAllowedPhases(values: any[], processPhasesAccessible: any, extended?: boolean) {
  const applicationStageIsEnabled = getFeatureConfigurationIsEnabled("Process Settings", "application-stage");
  const ret = values?.filter((_phase: any) =>
    processPhasesAccessible.find(
      (_processPhase: any) =>
        _processPhase.type === _phase.type || (applicationStageIsEnabled && _phase.type === PhaseType.start) || (extended && [PhaseType.start, PhaseType.end].includes(_phase.type))
    )
  );

  return ret;
}

export function isAdminUser() {
  const tenantStore = store.getState().tenant;
  const tenants = tenantStore.tenants;
  const alias = tenantStore.selectedTenant.alias;
  const role = getTenantRole(tenants, alias);
  const isAdmin = role === ROLE_SUPER_ADMIN || role === ROLE_ADMIN;
  return isAdmin;
}

export function getOptionsForSoftSkillsPass(_: string, processFormState: any) {
  let options: any[] = [{ key: "", label: "-" }];
  processFormState?.selectedTests?.forEach((softSkill: any) => {
    softSkill?.subtopics?.forEach((item: any) => {
      const option = options.length.toString();
      options.push({ key: option, label: option });
    });
  });
  return options;
}

export const skillsToPassCustomUpdate = (phase: any) => {
  let subTopicsCount = 0;
  phase.selectedTests?.forEach((skill: any) => {
    if (skill.subtopics) {
      subTopicsCount += skill.subtopics.length;
    }
  });

  if (parseInt(phase.skillsToPass) > subTopicsCount) {
    return { ...phase, skillsToPass: undefined };
  }
  return phase;
};

export function getOptionsForVideoInterviewSkillsPass(_: string, processFormState: any) {
  let options: any[] = [{ key: "", label: "-" }];
  processFormState?.questions?.forEach((__: any) => {
    const option = options.length.toString();
    options.push({ key: option, label: option });
  });
  return options;
}

export const videoInterviewSkillsToPassCustomUpdate = (phase: any) => {
  if (parseInt(phase.skillsToPass) > phase.questions.length) {
    return { ...phase, skillsToPass: undefined };
  }
  return phase;
};

export const videoConfigurationPhasesStartEnd = (phase: any, section: any) => {
  const { videoUrl, contentType, mediaType } = section;
  return {
    temp: true,
    ...phase,
    // isForProcess: true,
    loadFromLibrary: false,
    loadFromTemplate: false,
    loadFromProcess: false,
    videoUrl: videoUrl,
    contentType: contentType,
    mediaType: mediaType,
  };
};

export const videoConfigurationPhaseVideo = (phase: any, section: any) => {
  const { videoUrl, contentType, mediaType } = section;
  return {
    ...phase,
    video: {
      ...phase?.video,
      // isForProcess: true,
      loadFromLibrary: false,
      loadFromTemplate: false,
      loadFromProcess: false,
      videoUrl: videoUrl,
      contentType: contentType,
      mediaType: mediaType,
    },
  };
};

export function getVideoInterviewOptions(configKey: string) {
  const options: any = {
    answerTime: Array.from({ length: 20 }, (_value, i) => i + 1),
    maxRetries: [-1].concat(Array.from({ length: 10 }, (_value, i) => i + 1)),
    expireDate: Array.from(Array(10).keys()),
  };

  let label = "";
  let labelSingular = "";
  switch (configKey) {
    case "answerTime":
      label = toLiteral({ id: "creator.interview.answertime.minutes" });
      labelSingular = toLiteral({ id: "creator.interview.answertime.minute" });
      break;
    case "maxRetries":
      break;
    case "expireDate":
      label = toLiteral({ id: "attention.days" });
      labelSingular = toLiteral({ id: "attention.day" });
      break;
    default:
      break;
  }

  return options?.[configKey]?.map((option: any) => {
    let optionLabel = `${option} ${label}`;
    // single item label correction
    if (option === 1 && configKey !== "maxRetries") {
      optionLabel = `${option} ${labelSingular}`;
    }
    if (option === -1) {
      optionLabel = toLiteral({ id: "creator.interview.retries.infinite" });
    }
    if (option === 0 && configKey === "expireDate") {
      optionLabel = toLiteral({ id: "creator.interview.expiration.process" });
    }
    if (configKey === "answerTime") {
      option = option * 60;
    }
    return { key: option.toString(), label: toLiteral({ id: optionLabel }) };
  });
}

function getIntervalOptions(start: number, end: number, step: number, label: string) {
  let options: any = [];
  for (let i = start; i <= end; i = i + step) {
    options.push({ key: i.toString(), label: `${i} ${toLiteral({ id: label })}` });
  }
  return options;
}

export function getReferenceCheckFrequencyOptions() {
  return getIntervalOptions(12, 48, 12, "creator.liveinterview.duration.hours");
}

export function getCandidateReminderFrequencyOptions() {
  const intervalOptions = REMINDER_MAILS_FRECUENCY_FILTER.map((option) => option.key); //12 - 18 - 24 - 48
  const options = intervalOptions.map((option) => ({
    key: option.toString(),
    label: `${option} ${toLiteral({ id: "creator.liveinterview.duration.hours" })}`,
  }));
  return options;
}

export async function getAllDefaultTags(_: string, value: any, options: any) {
  let tagsOptions = value || [];
  options?.forEach((option: any) => {
    if (option?.default && !tagsOptions?.includes(option?.key)) tagsOptions?.push(option?.key);
  });
  return tagsOptions;
}

export function checkFeatureConfiguration(feature: any) {
  let isEnabled = true;
  if (feature) {
    if (Array.isArray(feature)) {
      feature?.forEach((featureItem: any) => {
        const featureEnabled = getFeatureConfigurationIsEnabled(featureItem?.channel, featureItem?.feature);
        if (featureEnabled !== featureItem?.enabled) isEnabled = false;
      });
    } else {
      const featureEnabled = getFeatureConfigurationIsEnabled(feature?.channel, feature?.feature);
      if (featureEnabled !== feature?.enabled) isEnabled = false;
    }
  }
  return isEnabled;
}
export async function getMediaUrl(selectedTenant: any, videoId: any, videoConfiguration?: any, isVideo?: boolean, processId?: string, videoSaved?: boolean) {
  if (videoConfiguration) {
    if (videoConfiguration.temp) {
      if (videoConfiguration.loadFromLibrary) {
        return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/videos/${videoId}`;
      } else if (videoConfiguration.loadFromTemplate) {
        return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processTemplates/${videoConfiguration.processTemplateId}/${videoId}`;
      } else if (videoConfiguration.loadFromProcess) {
        return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processes/${videoConfiguration.loadFromProcess}/${videoId}`;
      } else if (videoConfiguration.isForProcess) {
        return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processes/temp/${videoId}`;
      }
    } else {
      if (videoConfiguration.isForProcess && videoConfiguration.processId) {
        return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processes/${videoConfiguration.processId}/${videoId}`;
      } else {
        if (!videoSaved || !isVideo) {
          return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processes/temp/${videoId}`;
        } else {
          return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/processes/${processId}/${videoId}`;
        }
        // this is the correct one until fix temp return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/videos/${videoId}`;
      }
    }
  } else {
    return `${STORAGE_BASE_PATH}/tid${selectedTenant.id}/videos/${videoId}`;
  }
}

export function extractVideoTrueUrl(url: string) {
  let newUrl = url;
  if (url && url.split("/")[3] && url.split("/")[3] !== "embed") {
    if (url.split("/")[2] && url.split("/")[2] === "www.youtube.com") {
      const videoId = url.split("watch?v=")[1];
      newUrl = "https://www.youtube.com/embed/" + videoId;
    } else if (url.split("/")[2] && url.split("/")[2] === "vimeo.com") {
      const videoId = url.split("/")[3];
      newUrl = "https://player.vimeo.com/video/" + videoId;
    }
  }
  return newUrl;
}

export function extractVideoIdUrl(url: string) {
  let videoId = "";
  const _url = url?.split("/");
  if (_url?.[3] === "embed" || _url?.[3] === "video") videoId = _url?.[4];
  return videoId;
}

export async function uploadVideo(videoFile: any, newThumbnail: string, phase?: ProcessPhase) {
  //   Al añadir video si no hay video anterior, se hace upload y ya esta
  // Al añadir video si ya había uno antes, se hace upload del nuevo y si da success se hace delete del anterior
  // Se hace save cuando
  // UploadComponent: Seleccionas archivo
  // RecordComponent: Le das al boton "Save Video" (sale en el diseño)
  // LinkComponent: No hay que guardar nada, pero si añades link si, eliminas lo que había antes
  // LibraryComponente: Le das al boton "Save Video" (no existe en el diseño pero les voy a decir que lo añadan porque no tiene sentido que no este)
  try {
    const videoResponse = await Api.uploadVideo("videotest", videoFile, "asd", newThumbnail, "process");
    return videoResponse;
  } catch (e) {
    // commented code catchMediaError(e, functions.setMaxFileSize, functions.setIsUploadFileError, functions.onShowSnackbar, functions.setIsUploading);
  }
}

export function onContextMenu(e: any) {
  e.preventDefault();
}

export function captureThumbnail(id: string, setThumbnail: Function) {
  let playerVideo = document.getElementById(id) as any;
  playerVideo.muted = true;
  let thumb = "";
  clearTimeout(delayTimer);
  delayTimer = setTimeout(function () {
    thumb = generateThumbnail(playerVideo);
    setThumbnail(thumb);
  }, 500);
}

export function getNpsByPhases(phases: any[]) {
  const npsScreeningQuestionsFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-screening-questions");
  const npsVideoInterviewFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-video-interview");
  const npsLiveInterviewFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-live-interview");
  const npsHardSkillsFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-hard-skills");
  const npsSoftSkillsFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-soft-skills");
  const npsVideoFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-video");
  const npsPhoneCallFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-phonecall");
  const npsCandidatePresentationFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-candidate-presentation");
  const npsShortlistFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-shortlist");
  const npsReferenceCheckFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-reference-check");
  const npsOfferManagementFeature = getFeatureConfigurationIsEnabled("candidate-feedback", "nps-offermanagement");
  let npsPhases: any[] = [];
  phases.forEach((phase: any) => {
    if (phase?.type === PhaseType.video && npsVideoFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.screeningQuestions && npsScreeningQuestionsFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.videoInterview && npsVideoInterviewFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.liveInterview && npsLiveInterviewFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.hardSkill && npsHardSkillsFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.softSkill && npsSoftSkillsFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.phoneCall && npsPhoneCallFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.candidatePresentation && npsCandidatePresentationFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.shortlist && npsShortlistFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.reference && npsReferenceCheckFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
    if (phase?.type === PhaseType.offerManagement && npsOfferManagementFeature) {
      npsPhases.push({ type: "switch", validation: [], title: phase?.name, key: `customNpsProcessPhase_${phase?.type?.toString()}` });
    }
  });
  return npsPhases;
}

function generateThumbnail(video: any): string {
  let w = video.videoWidth;
  let h = video.videoHeight;
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  if (ctx === undefined || ctx === null) return "";
  ctx.drawImage(video, 0, 0, w, h);
  try {
    let dataURI = canvas.toDataURL("image/jpeg");
    let base64 = dataURI.replace("data:image/jpeg;base64,", "");
    return base64;
  } catch (e) {
    console.error("Couln't render because crossorigin: ", (e as any).message);
    return "";
  }
}

export function getScoreToPassValue(data: any) {
  let totalRequiredScore = 0;
  data?.questions?.forEach((question: any) => {
    if (question?.isRequired || question?.sqQuestionAType === "isRequired") {
      if (!question?.isPartialScore) totalRequiredScore += parseInt(question?.weight);
      else if (question?.sqAnswerType === "isExactMatch" || question?.isExactMatch) {
        question?.answers?.forEach((answer: any) => {
          if (answer?.weight !== undefined && answer?.isCorrect) totalRequiredScore += parseInt(answer?.weight);
        });
      } else {
        let _minValue = 0;
        question?.answers?.forEach((answer: any) => {
          if (answer?.weight !== undefined && answer?.isCorrect) {
            if (_minValue === 0 || (_minValue && parseInt(answer?.weight) <= _minValue)) _minValue = parseInt(answer?.weight);
          }
        });
        totalRequiredScore += _minValue;
      }
    }
  });
  return totalRequiredScore;
}

export function getQuestionTypeOptions() {
  const options: { key: string; label: string }[] = [{ key: "multipleChoice", label: "screening.multipleChoice" }]; // default option and always enabled

  if (getFeatureConfigurationIsEnabled("Process Settings", "free-type-answers")) {
    options.push({ key: "freeText", label: "screening.freeTypeAnswer" });
  }

  if (getFeatureConfigurationIsEnabled("Process Settings", "date-type-answers")) {
    options.push({ key: "dateType", label: "screening.dateTypeAnswers" });
  }

  if (getFeatureConfigurationIsEnabled("Process Settings", "options-list-type-answers")) {
    options.push({ key: "listType", label: "screening.optionsListTypeAnswers" });
  }

  if (getFeatureConfigurationIsEnabled("Process Settings", "binary-type-answers")) {
    options.push({ key: "binaryType", label: "screening.binaryTypeAnswers" });
  }

  return options;
}

export function isExpirationDateDisabled(_process: any) {
  // Data
  const isExpired = _process?.status === ProcessStatus.expire;
  const isRCProcess = _process?.source === Sources.recruitmentConnect;
  // Features
  const editExpirationExternal = getFeatureConfigurationIsEnabled("General Settings", "expiration-date-editable");
  // Validation => If process is open and is external, dont able to edit unless feature is enabled
  if (isRCProcess) return false;
  else if (editExpirationExternal && !isExpired) return false;
  else return true;
}

export function isCorrectAnswer(sectionFormState: any, arrayIndex: number) {
  const answer = sectionFormState?.answers?.[arrayIndex];
  if (answer?.isCorrect || answer?.isCorrect === "true") return true;
  return false;
}

export function getScreeningQuestionRejectMessageOptions(phase: any) {
  const customRejection = getFeatureConfiguration("Process Phases", "screening-custom-rejection");
  const allOptions = customRejection?.config;
  const options = allOptions?.[CURRENT_LOCALE] ? allOptions?.[CURRENT_LOCALE] : allOptions?.[DEFAULT_LANGUAGE];
  const returnOptions: { key: string; label: string }[] = [];
  if (options) {
    Object.keys(options).forEach((key: string) => {
      returnOptions.push({ key: options?.[key], label: options?.[key]?.length > 100 ? options?.[key]?.substring(0, 100) + "..." : options?.[key] });
    });
  }

  return returnOptions;
}

export async function getDefaultReminderValues(key: string) {
  let defaultReminderValue;
  const reminderValues = getFeatureConfiguration("Process Settings", "automatic-reminder-for-candidates");
  if (key === "amount") defaultReminderValue = reminderValues?.config?.amount;
  else defaultReminderValue = reminderValues?.config?.frequency;
  return defaultReminderValue;
}

export async function getDefaultCurrencyValue(_: string, value: any) {
  let defaultCurrencyValue = value || "";
  const offerManagementConfig = getFeatureConfiguration("Process Phases", "offer-management");
  const offerManagementDefaultCurrency = offerManagementConfig?.config?.["offer-management-currency"] || [];
  if (offerManagementConfig?.isEnabled && offerManagementDefaultCurrency?.length > 0) {
    defaultCurrencyValue = offerManagementDefaultCurrency?.[0];
  }
  return defaultCurrencyValue;
}

export function getDefaultVideoInterviewValues(key: string) {
  const cammioConfig = getFeatureConfigurationValues("CAMMIO", "cammio-integration");
  return cammioConfig?.[key];
}

export function evaluateCAIntegrationRestrictions(process: Process, phaseIndex: number) {
  // Check if the first phase has a video URL and if the screening is the second phase
  const firstPhaseVideoDisabled = !!process?.phases[0]?.videoUrl && phaseIndex === 1;
  // Check if the end phase has a video URL and if the screening is the one right before the end phase
  const lastPhaseVideoDisabled = !!process?.phases[process?.phases.length - 1]?.videoUrl && phaseIndex === process?.phases.length - 2;
  // Determines if any question within the screening phase has associated media URLs.
  const anyQuestionMediaDisabled = process?.phases[phaseIndex]?.questions?.some((question) => Array.isArray(question?.mediaUrls) && question?.mediaUrls.length > 0) || false;
  // Check if the first phase has the automatic reminder enabled and if the screening is the second phase
  const firstPhaseReminderEnabled = process?.phases[0]?.automaticRemainder === "on" && phaseIndex === 1;
  return {
    firstPhaseVideoDisabled,
    lastPhaseVideoDisabled,
    anyQuestionMediaDisabled,
    firstPhaseReminderEnabled,
  };
}

export function generateCADisabledMessage(firstPhaseVideoDisabled: boolean, lastPhaseVideoDisabled: boolean, anyQuestionMediaDisabled: boolean, firstPhaseReminderEnabled: boolean) {
  if (firstPhaseVideoDisabled && firstPhaseReminderEnabled) {
    return toLiteral({ id: "To enable CA Chatbot disable incompatible settings on the 'Start' phase: 'Recruiter Video' and 'Automatic reminder for candidates'" });
  } else if (firstPhaseVideoDisabled && !firstPhaseReminderEnabled) {
    return toLiteral({ id: "Please remove the Recruiter Video from the 'Start' phase before enabling CA chatbot" });
  } else if (!firstPhaseVideoDisabled && firstPhaseReminderEnabled) {
    return toLiteral({ id: "Please remove the `'Automatic reminder for candidates' from the 'Start' phase before enabling CA chatbot" });
  } else if (lastPhaseVideoDisabled) {
    return toLiteral({ id: "Please remove the Recruiter Video from the 'Finish' phase before enabling CA chatbot" });
  } else if (anyQuestionMediaDisabled) {
    return toLiteral({ id: "Please remove media from questions before enabling CA chatbot" });
  }
}

export function filterTemplates(templates: any[], moduleName: string | undefined, isCADrivenEnabled: boolean | undefined) {
  const externalTemplates = templates?.filter((template: any) => template?.externalId !== null);
  const onlyExternalTemplates = isCADrivenEnabled && moduleName === "screeningByCA";
  const compatibleModuleTemplates = (onlyExternalTemplates ? externalTemplates : templates)?.filter((template: any) => template?.usage?.includes(moduleName));
  const SMSTemplates = compatibleModuleTemplates?.filter((template: any) => template?.type === "sms");
  const EMAILTemplates = compatibleModuleTemplates?.filter((template: any) => template?.type === "email");

  return { SMSTemplates, EMAILTemplates };
}

export async function fetchAndSetTemplates(setTemplates: Function) {
  try {
    const response = await Api.getAllNotificationTemplates({});
    setTemplates(response?.data?.NotificationTemplate);
  } catch (error) {
    addNotification({ type: "error", content: toLiteral({ id: "requestErrorMessage.generic" }), timer: 5 });
  }
}

export function getUserProfileOptions() {
  const options = PHASE_PROFILES?.map((userProfile) => {
    return { key: userProfile?.value, label: userProfile?.name };
  });
  return options;
}
